import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("sys/group/role/list", { params });
}

export function Add(params) {
  return request.post("sys/group/role", params);
}


export function Delete(data) {
  return request.delete("sys/group/role", { data });
}
