import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("sys/group/employee/list", { params });
}

export function Add(params) {
  return request.post("sys/group/employee", params);
}


export function Delete(data) {
  return request.delete("sys/group/employee", { data });
}
