<template>
  <div>
    <a-modal v-model:visible="visible" title="关联权限" @ok="handleOk" okText="确认" cancelText="取消" :afterClose="closeModal" :cancelButtonProps="{ style: { display: 'none' }}" width="55vw">
      <div class="head">
        <a-form-item label="门店">
          <clubSelector v-model="clubId" />
        </a-form-item>
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="data" rowKey="id" :pagination="{pageSize:6 }" bordered>
        <template #title>
          <div class="thead">关联权限</div>
        </template>
        <template #operation="{ record }">
          <div class="flex_sa">

            <div class="editable-row-operations">
              <span>
                <a-popconfirm v-if="data.length" title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>
          </div>
        </template>
      </a-table>
      <div class="head2">
        <span>名称</span>
        <employeeSelector v-model="employeeId" />
        <a-button @click="addToTable()">添加</a-button>
      </div>
      <!-- <div style="text-align: center;margin-top: 20px;">
        <a-button type="primary" @click="visible2=true">新增权限</a-button>
      </div> -->
    </a-modal>
  </div>
  <!-- <addPrescription v-model="visible2" @getData="callbackData" /> -->
  <addPermission v-model="visible2" />
</template>

<script>
import { reactive } from "vue";
import { Add, Search, Delete } from "/src/api/groupEmployee.js";
import employeeSelector from '/src/components/selector/employeeSelector.vue';
import addPermission from '/src/components/permission/addPermission.vue';
import clubSelector from '/src/components/selector/clubSelector.vue';
export default {
  components: {
    employeeSelector,
    addPermission,
    clubSelector
  },
  data() {
    return {
      visible: this.modelValue,
      visible2: false,
      reload: false,
      medicineFilter: "",
      employeeId: "",
      clubId: "",
      selectedRows: {},
      columns: [
        {
          title: "名称",
          key: "employeeName",
          dataIndex: "employeeName",
          width: "75%",
          slots: {
            customRender: "employeeName"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "25%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: {}
    },
  },
  computed: {
  },
  methods: {
    filter() {
      return true
    },
    async detail() {
      console.log(this.record);
      let res = await Search({ groupId: this.record.id })

      console.log(res);
      // console.log(res)
      if (res.errCode == 0) {
        this.data = res.data
      } else {
        this.$message.error("详情查询失败", function () { });
      }
    },
    async handleOk() {
      let _this = this;
      let res = await Add({ groupId: this.record.id, permissionId: this.permissionId })
      if (res.errCode == 0) {
        _this.$message.success("关联成功", function () { });
        _this.visible = false
        // _this.reload = true
        this.permissionIdList = []
      }
    },
    async addToTable() {

      //   this.data.push(...arr)
      let res = await Add({
        groupId: this.record.id,
        employeeId: this.employeeId,
      })
      this.employeeId = ""
      this.detail()

    },
    async onDelete(id) {

      let res = await Delete({ id })
      if (res.errCode == 0) {
        this.data = this.data.filter(function (item) {
          return item.id !== id;
        });
      }


    },
    closeModal() {
      this.$emit('update:modelValue', false);
      //   if (this.reload) {
      //     this.$emit("reload")
      //   }
    },

  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
.head2 {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}
.head2 span {
  font-weight: bold;
  margin-right: 10px;
}
</style>
